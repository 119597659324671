<template>
  <q-card>
    <q-card-section>
      <q-timeline>
        <q-timeline-entry
          :color="minExpiredAtBadgeColor"
          class="origin-q-timeline-entry"
          icon="fas fa-plane"
        >
          <template v-slot:subtitle>
            <span>{{ formattedMinExpiredAt }}</span>
            <span> · </span>
            <strong
              :class="`text-${minExpiredAtBadgeColor}`"
            >{{ relativeMinExpiredAt }}</strong>
          </template>
          <template v-slot:title>
            {{ value.originAirportId }}
            <q-icon
              class="q-mx-xs"
              name="fas fa-plane"
            />
            {{ value.destinationAirportId }}
          </template>
          <div>
            <q-btn
              :href="`https://server.fseconomy.net/airport.jsp?icao=${value.destinationAirportId}&toAirport=1&airportArea=0&aircraftArea=0#:~:text=${value.originAirportId}`"
              color="primary"
              dense
              flat
              target="_blank"
              type="a"
            >
              <q-icon
                left
                name="fas fa-briefcase"
              />
              <span>FSEconomy</span>
            </q-btn>
            <q-btn
              :href="`https://server.fseconomy.net/airport.jsp?icao=${value.originAirportId}#aircraftForm`"
              color="secondary"
              dense
              class="q-ml-md"
              flat
              target="_blank"
              type="a"
            >
              <q-icon
                left
                name="fas fa-plane"
              />
              <span>Aircrafts</span>
            </q-btn>
          </div>
        </q-timeline-entry>
        <q-timeline-entry
          :color="minExpiredAtBadgeColor"
          class="destination-q-timeline-entry"
          icon="fas fa-plane fa-rotate-180"
        >
          <template v-slot:title>
            {{ value.destinationAirportId }}
            <q-icon
              class="q-mx-xs"
              name="fas fa-plane"
            />
            {{ value.originAirportId }}
          </template>
          <div>
            <q-btn
              :href="`https://server.fseconomy.net/airport.jsp?icao=${value.destinationAirportId}#:~:text=${value.originAirportId}`"
              color="primary"
              dense
              flat
              target="_blank"
              type="a"
            >
              <q-icon
                left
                name="fas fa-briefcase"
              />
              <span>FSEconomy</span>
            </q-btn>
          </div>
        </q-timeline-entry>
      </q-timeline>
    </q-card-section>
  </q-card>
</template>

<script>
  import moment from 'moment'

  export default {
    computed: {
      minExpiredAtBadgeColor: {
        get () {
          const hours = moment(this.value.minExpiredAt).diff(
            moment(),
            'hours',
            true,
          )

          if (hours <= 0) {
            return 'dark'
          } else if (hours <= 4) {
            return 'negative'
          } else if (hours <= 12) {
            return 'warning'
          }

          return 'info'
        },
      },
      formattedMinExpiredAt: {
        get () {
          return moment(this.value.minExpiredAt).format('ddd, YYYY-MM-DD HH:mm')
        },
      },
      relativeMinExpiredAt: {
        get () {
          return moment(this.value.minExpiredAt).fromNow()
        },
      },
    },
    props: {
      value: {
        type: Object,
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .q-timeline__subtitle {
      opacity: 1;
    }

    .destination-q-timeline-entry {
      .q-timeline__content {
        margin-top: -8px;
      }
    }
  }
</style>
