<template>
  <section class="q-pa-md">
    <h1>
      {{ value.id || $route.params.id }}
    </h1>
    <h2>
      Assignments
      <q-btn
        color="primary"
        icon="fas fa-sync"
        label="Refresh"
        @click="refreshAirportAssignment"
      />
    </h2>
    <p>
      This section shows routes between two airports that have at least five
      passenger terminal assignments (a.k.a. green jobs) going and coming back.
    </p>
    <p>
      Routes are sorted by their urgency (expiring soonest first), you're
      recommended to start flying from the top of the list.
    </p>
    <route-assignments-list-card
      :loading="loading"
      :value="routeAssignmentsList"
    />
  </section>
</template>

<script>
  import RouteAssignmentsListCard
    from 'pages/_locale/group/RouteAssignmentsListCard'

  const moduleName = 'group'

  export default {
    components: { RouteAssignmentsListCard },
    methods: {
      async refreshAirport () {
        let response = null
        try {
          response = await this.$api.get(
            `airport/${this.$route.params.id}`,
          )
        } catch (error) {
          // await this.$router.push(
          //   `/${this.$route.params.locale}/airport`,
          // )

          return
        }

        this.value = response.data.data
      },
      async refreshAirportAssignment () {
        this.loading = true

        let response = null
        try {
          response = await this.$api.get(
            `assignment/aggregated`,
            {
              params: {
                'airportIds': [
                  this.$route.params.id,
                ],
              },
            },
          )
        } catch (error) {
          this.loading = false

          return
        }

        this.routeAssignmentsList = response.data.data
        this.loading = false
      },
      async refresh () {
        await Promise.all(
          [
            this.refreshAirport(),
            this.refreshAirportAssignment(),
          ],
        )
      },
    },
    async created () {
      await this.refresh()
    },
    data () {
      return {
        loading: false,
        value: {},
        routeAssignmentsList: [],
      }
    },
    meta () {
      return {
        title: `${this.$route.params.id} | Airports`,
      }
    },
  }
</script>
