import { render, staticRenderFns } from "./RouteAssignmentsCard.vue?vue&type=template&id=2c99dca6&scoped=true&"
import script from "./RouteAssignmentsCard.vue?vue&type=script&lang=js&"
export * from "./RouteAssignmentsCard.vue?vue&type=script&lang=js&"
import style0 from "./RouteAssignmentsCard.vue?vue&type=style&index=0&id=2c99dca6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c99dca6",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QTimeline,QTimelineEntry,QIcon,QBtn});
