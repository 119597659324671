<template>
  <div class="q-gutter-y-md q-mt-md">
    <template v-if="!$_.isEmpty(value)">
      <route-assignments-card
        :value="routeAssignments"
        :key="`${routeAssignments.originAirportId}-${routeAssignments.destinationAirportId}`"
        v-for="routeAssignments of value"
      />
    </template>
    <template v-else-if="!loading">
      <q-banner
        class="bg-grey-4"
        dense
      >
        <template v-slot:avatar>
          <q-icon
            color="warning"
            name="fas fa-exclamation-triangle"
          />
        </template>
        <p>
          There are no routes matching the criteria at this moment,
          please check back later.
        </p>
      </q-banner>
    </template>
    <template v-else>
      <q-banner
        class="bg-grey-4"
        dense
      >
        <template v-slot:avatar>
          <q-icon
            color="primary"
            name="fas fa-spinner"
          />
        </template>
      </q-banner>
    </template>
  </div>
</template>

<script>
  import RouteAssignmentsCard from 'pages/_locale/group/RouteAssignmentsCard'

  export default {
    components: { RouteAssignmentsCard },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Array,
      },
    },
  }
</script>
